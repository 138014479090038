<template>
  <div id="finance-recharge">
    <h4>充值<b-button v-b-modal.modal-1 class="float-right" size="sm" variant="primary">添加充值记录</b-button></h4>
    <div class="my-3">
      <b-modal id="modal-1" title="添加充值记录" @ok="onFormSubmit" :ok-title="'保存'" :cancel-title="'取消'">
        <form action="">
              <div class="mb-3 row">
                <label for="name" class="col-sm-2 col-form-label">流水号</label>
                <div class="col-sm-10">
                  <input type="text" name="name" class="form-control" id="name">
                </div>
              </div>

              <div class="mb-3 row">
                <label for="inputGender" class="col-sm-2 col-form-label">渠道</label>
                <div class="col-sm-10">
                  <select class="form-select form-control" name="gender" id="inputGender">
                    <option selected>请选择渠道</option>
                    <option value="1">中国移动</option>
                    <option value="2">中国联通</option>
                    <option value="2">中国电信</option>
                  </select>
                </div>
              </div>

              <div class="mb-3 row">
                <label for="inputIncome" class="col-sm-2 col-form-label">金额</label>
                <div class="col-sm-10">
                  <input type="text" name="text" class="form-control" id="inputIncome">
                </div>
              </div>
              <div class="mb-3 row">
                <label for="inputBody" class="col-sm-2 col-form-label">备注</label>
                <div class="col-sm-10">
                  <textarea class="form-control" name="body" id="inputBody"></textarea>
                </div>
              </div>
        </form>
      </b-modal>
      <Loading :loading="loading"/>
      <b-table :items="list" :fields="fields" hover striped head-variant="light"/>
    </div>

  </div>
</template>
<script>
// import Finance from "@/model/Finance"
import Recharge from "@/model/Recharge"
// import Card from "@/components/Card"
import Loading from "@/components/Loading"

export default {
  components:{
    Loading,
  },
  data() {
    return {
      fields:[
        {key:'orderSn',label:'充值单号'},
        {key:'created_at', label:'提交时间'},
        {key:'amount',label:'充值金额'},
        {key:'status', label:'充值状态'},
      ],
      list: [],
      keywords: {
        start: '',
        end: '',
        type: -1,
        channel: -1,
        orderSn: ''
      },
      loading: true
    }
  },

  mounted() {
    this.init()
  },
  methods: {
    init() {
      Recharge.list(this.keywords).then(response => {
        if(response.data.code === 0) {
          this.list = response.data.data;
        }
        this.loading = false
      })
    },
    onFilterSubmit(e) {
      e.preventDefault();
      this.loading = true;
      setTimeout(() => {this.loading = false}, 500)
      this.init();
    },
    onFormSubmit(e) {
      e.preventDefault();
      this.loading = true;
      setTimeout(() => {this.loading = false}, 500)
      this.init();
    }
  }
}
</script>